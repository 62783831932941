.sidenav {
  height: 200vh;
  background-color: rgba(29, 65, 122, 1);
}
.sidenav a {
  text-align: center;
  display: block;
  margin-top: 100px;
  color: #f9fbfd;
  font-size: 16px;
  text-decoration: none;
}
.sidenav a:hover {
  color: #f9fbfd;
}
.logo {
  padding-top: 20px;
  padding-bottom: 30px;
  border-bottom: 1px solid #9b9a9a;
  margin: 0 20px;
}
